import React, { useEffect, useState } from "react";
import { Button, Card, Grid, Tooltip, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  DataGrid,
  GridColumnMenu,
  GridColumnMenuProps,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import Spinner from "components/Spinner";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import FormDialog from "components/Dialog/modal-popup";
import AddTargetForm from "./AddTargetForm";
import { GHGTargetGridProps } from "./data/GHGTartgetGrid.type";
import {
  _deleteGHGTarget,
  _getCustomGHGTargetsByOrganizationsId,
  _getGHGTargetsByTargetNumber,
} from "middlewares/TargetformApi/targetform";
import { GHGTarget } from "./data/GHGTarget";
import { initialCategories } from "./data/TargetFormData";
import { Scope3GHGReductionModel } from "./data/Scope3GHGReduction";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import SnackBar from "components/SnackBar";
import AlertDialog from "components/AlertDialog";
import { ConfirmationMessages, Constants } from "constants/constant";

const defaultGHGTarget: GHGTarget = {
  order: 0,
  targetNumber: 0,
  orgID: 0,
  targetName: "",
  baseYear: Constants.BaseYear,
  targetYear: Constants.TargetYear,
  scopesCovered: "",
  scope1Reduction: "0",
  scope2Reduction: "0",
  scope3GHGReduction: null,
};

/**
 * Custom column menu component to hide certain menu items.
 * @param {GridColumnMenuProps} props - The properties passed to the column menu.
 * @returns {JSX.Element} Custom column menu.
 */

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
};

const TargetForm = () => {
  // States
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gHGTargetsData, setGHGTargetsData] = useState<
    Array<GHGTargetGridProps>
  >([]);
  const [targetForm, setTargetForm] = useState<GHGTarget>(defaultGHGTarget);
  const [category, setCategory] =
    useState<Scope3GHGReductionModel[]>(initialCategories);
  const [success, setSuccess] = useState<AlertsProps>({
    show: false,
    severity: "info",
    message: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [targetNumber, setTargetNumber] = useState(0);
  const [targetName, setTargetName] = useState("");
  // Params
  const { OrganizationId } = useParams();

  /**
   * Hides the dialog.
   */
  const hideDialogHandler = () => setDialogOpen(false);
  
  /**
   * Handles closing the Snackbar.
   * @param {AlertsProps} state - The state of the Snackbar.
   */
  const handleCloseSnackBar = (state: AlertsProps) => {
    setSuccess(state);
  };
  
   /**
   * Fetches custom GHG targets by organization ID.
   */
  const getCustomGHGTargetsByOrganizationsId = async () => {
    try {
      setLoading(true);
      const res = await _getCustomGHGTargetsByOrganizationsId(
        Number(OrganizationId)
      );
      if (res) {
        setGHGTargetsData(res);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

   /**
   * Fetches GHG target data by target number.
   * A GHG (Greenhouse Gas) Target refers to a specific goal 
   * set by an organization to reduce its greenhouse gas emissions over a set period
   * @param {number} targetNumber - The target number.
   */
  const getGHGTargetsByTargetNumber = async (targetNumber: number) => {
    try {
      setLoading(true);
      const res = await _getGHGTargetsByTargetNumber(targetNumber);
      if (res) {
        const targetWithDefaultBaseYear: GHGTarget = {
          ...res,
          baseYear: res.baseYear || defaultGHGTarget.baseYear,
          targetYear: res.targetYear || defaultGHGTarget.targetYear,
        };
        setTargetForm(targetWithDefaultBaseYear);
        setDialogOpen(true);
        setDialogTitle("Edit Target Form");
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  // Effect
  useEffect(() => {
    getCustomGHGTargetsByOrganizationsId();
  }, []);

    /**
   * Handles editing of a GHG target.
   * @param {number} targetNumber - The target number.
   */
  const handleEdit = (targetNumber: number) => {
    getGHGTargetsByTargetNumber(targetNumber);
  };

  /**
   * Renders a tooltip cell with truncated content.
   * @param {any} params - The cell parameters.
   * @returns {JSX.Element | string} Tooltip cell or content.
   */
  const renderTooltipCell = (params: any) => {
    const content = params.value || ""; // Handle possible null or undefined values
    const maxLength = 50; // Maximum length before truncating and showing tooltip
    const parts = content.split(",");
    return content.length > maxLength ? (
      <Tooltip
        title={
          <ul>
            {parts.map((part: any, index: number) => (
              <li key={part.trim()}>{part.trim()}</li>
            ))}
          </ul>
        }
      >
        <div
          style={{
            maxWidth: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {content.slice(0, maxLength) + "..."}
        </div>
      </Tooltip>
    ) : (
      content
    );
  };

   /**
   * Opens the dialog.
   */
  const openDialog = () => {
    setOpenModal(true);
  };

  /**
   * Closes the dialog.
   */
  const handleClose = () => {
    setOpenModal(false);
  };

   /**
   * Handles delete target click event.
   * @param {number} targetNumber - The target number.
   * @param {string} targetName - The target name.
   */
  const handleDeleteClick = (targetNumber: number, targetName: string) => {
    openDialog();
    setTargetNumber(targetNumber);
    setTargetName(targetName);
  };

  /**
   * Deletes a GHG(Greenhouse Gas) target.
   */
  const deleteGHGTarget = async () => {
    try {
      const res = await _deleteGHGTarget(targetNumber);
      if (res) {
        getCustomGHGTargetsByOrganizationsId();
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  /**
   * Handles confirmation of deletion.
   */
  const handleConfirm = () => {
    deleteGHGTarget();
    handleClose();
    handleCloseSnackBar({
      show: true,
      severity: "success",
      message: "Deleted Successfully !",
    });
  };

  const columns = [
    {
      field: "targetName",
      headerClassName: "table-header",
      headerName: "Target Name",
      minWidth: 200,
    },
    {
      field: "baseYear",
      headerClassName: "table-header",
      headerName: "Base Year",
      minWidth: 100,
    },
    {
      field: "targetYear",
      headerClassName: "table-header",
      headerName: "Target Year",
      minWidth: 100,
    },
    {
      field: "scopesCovered",
      headerClassName: "table-header",
      headerName: "Scopes Covered",
      minWidth: 150,
    },
    {
      field: "scope3Categories",
      headerClassName: "table-header",
      headerName: "Scope 3 Categories",
      minWidth: 200,
      flex: 1,
      renderCell: renderTooltipCell,
    },
    {
      field: "baselineValue",
      headerClassName: "table-header",
      headerName: "Baseline Value",
      minWidth: 150,
      renderCell: (params: any) => (
        <div style={{ textAlign: "center", width: "80%" }}>
          {params.value.toLocaleString("en-US")}
        </div>
      ),
    },
    {
      field: "reduction",
      headerClassName: "table-header",
      headerName: "% Reduction",
      minWidth: 150,
      valueFormatter: (value: GridValueFormatterParams) => `${value.value} %`,
    },
    {
      field: "actions",
      headerClassName: "table-header",
      headerName: "Actions",
      width: 100,
      renderCell: (params: any) => (
        <div>
          <span title="Edit Target" style={{ cursor: "pointer" }}>
            <EditIcon
              style={{ color: "#e4610f" }}
              onClick={() => handleEdit(params.row.targetNumber)}
              data-testid="editconTestId"
            />
          </span>
          <span title="Delete Target" style={{ cursor: "pointer" }}>
            <DeleteOutlineIcon
              onClick={() =>
                handleDeleteClick(
                  params.row.targetNumber,
                  params.row.targetName
                )
              }
              data-testid="deleteIconTestId"
              style={{
                color: "#e4610f",
                cursor: "pointer",
              }}
            />
          </span>
        </div>
      ),
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={4} md={6.5}>
          <Typography
            fontSize="24px"
            fontFamily={"Inter"}
            fontWeight={700}
            data-testid="GHGTargetsLabel"
          >
            GHG Targets
          </Typography>
        </Grid>
        <Grid item xs={4} md={5.5} textAlign={"right"}>
          <Button
            variant="contained"
            data-testid="AddNewTargetButton"
            onClick={() => {
              setDialogOpen(true);
              setDialogTitle("Add New Target Form");
              setTargetForm(defaultGHGTarget);
              setCategory(initialCategories);
            }}
          >
            Add New Target
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Grid container sx={{ mt: 0, mb: 0 }}>
              <Grid item xs={12}>
                {loading ? (
                  <Spinner size={80} data-testid="spinner" />
                ) : (
                  <DataGrid
                    rows={gHGTargetsData}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    pageSizeOptions={[5, 10, 15, 20]}
                    disableRowSelectionOnClick
                    getRowId={(row) => row?.targetNumber}
                    slots={{ columnMenu: CustomColumnMenu }}
                    autoHeight
                    columnBuffer={20}
                  />
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <FormDialog
        dialogOpen={dialogOpen}
        hideDialogHandler={hideDialogHandler}
        dialogTitle={dialogTitle}
        isFullScreenAllowed={true}
        childComponent={
          <AddTargetForm
            handleCloseSnackBar={handleCloseSnackBar}
            hideDialogHandler={hideDialogHandler}
            getCustomGHGTargetsByOrganizationsId={
              getCustomGHGTargetsByOrganizationsId
            }
            defaultGHGTarget={targetForm}
            initialCategories={category}
          ></AddTargetForm>
        }
      ></FormDialog>

      <SnackBar
        show={success.show}
        message={success.message}
        severity={success.severity}
        onclose={() =>
          handleCloseSnackBar({ show: false, severity: "info", message: "" })
        }
      />
      <AlertDialog
        open={openModal}
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        organizationName={targetName}
        confirmationMessage={ConfirmationMessages.ghgConfirmation}
      />
    </React.Fragment>
  );
};

export default TargetForm;
