import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Search from "components/Search";
import OrganizationsCard from "./OrganizationsCard";
import { OrganizationCardProps } from "./data/HomePage.type";
import { _getOrganizations } from "middlewares/OrganizationApi/organization";
import Spinner from "components/Spinner";
import FormDialog from "components/Dialog/modal-popup";
import CreateUpdateOrganization from "pages/CreateUpdateOrganization";
import SnackBar from "components/SnackBar";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import { useAppState } from "context/appState.context";
import { useMsal } from "@azure/msal-react";
import { UserRoles } from "constants/constant";
import { _getUserRole } from "middlewares/ManageUsers/manageusers";
import { useNavigate } from "react-router-dom";

/**
 * DisplayOrganizations Component
 *
 * This component is responsible for displaying the list of organizations.
 * It handles fetching organizations, filtering, and rendering organization cards.
 *
 * @returns {JSX.Element} The rendered DisplayOrganizations component.
 */
const DisplayOrganizations: React.FC = () => {
  const { userRole, setUserRole } = useAppState();
  const { accounts } = useMsal();

  const [dialogTitle, setDialogTitle] = useState("");
  const [role, setRole] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [organizationsData, setOrganizationsData] = useState<
    Array<OrganizationCardProps>
  >([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    Array<OrganizationCardProps>
  >([]);
  const [success, setSuccess] = useState<AlertsProps>({
    show: false,
    severity: "info",
    message: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.removeItem("userRole");
    if (accounts.length > 0) {
      const email = accounts[0].idTokenClaims?.email as string;
      if (email?.length) {
        getUserRole(email);
      }
    }
  }, [accounts]);

  /**
   * Fetches the user role based on the email.
   *
   * @param {string} email - User's email.
   */
  const getUserRole = async (email: string) => {
    try {
      const res = await _getUserRole(email);
      if (res) {
        setUserRole(res);
        setRole(res);
        sessionStorage.setItem("userRole", res);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  useEffect(() => {
    if (role) {
      getEssentialOrganizationDetails();
    }
  }, [role]);

  /**
   * Fetches the list of organizations based on the user role.
   */
  function getEssentialOrganizationDetails() {
    setLoading(true);
    _getOrganizations(userRole)
      .then((res) => {
        if (res) {
          setOrganizationsData(res);
          setFilteredOrganizations(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error:", err);
        setLoading(false);
      });
  }

  /**
   * Closes the snackbar.
   *
   * @param {AlertsProps} state - Snackbar state.
   */
  const handleCloseSnackBar = (state: AlertsProps) => {
    setSuccess(state);
  };

  /**
   * Refreshes the list of organizations.
   */
  const refreshOrganizations = () => {
    getEssentialOrganizationDetails();
  };

  /**
   * Filters the list of organizations based on the search input.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - Search input change event.
   */
  const organizationsFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setFilteredOrganizations(
      organizationsData.filter((item: OrganizationCardProps) =>
        item.orgName.toLowerCase().includes(searchValue)
      )
    );
  };

  /**
   * Renders the list of organizations.
   *
   * @returns {JSX.Element} The rendered organizations.
   */
  const renderOrganizations = () => {
    if (filteredOrganizations.length === 0) {
      return (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            id="test-typography-01"
            component="div"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            No Organizations Found
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid container spacing={2} data-testid="displayOrganizations">
        {filteredOrganizations.map((item: OrganizationCardProps) => (
          <Grid key={item.orgId} item xs={6} md={3}>
            <OrganizationsCard
              {...item}
              handleCloseSnackBar={handleCloseSnackBar}
              refreshOrganizations={refreshOrganizations}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <FormDialog
        dialogOpen={dialogOpen}
        hideDialogHandler={() => setDialogOpen(false)}
        dialogTitle={dialogTitle}
        isFullScreenAllowed={true}
        childComponent={
          <CreateUpdateOrganization
            closeDialog={() => setDialogOpen(false)}
          ></CreateUpdateOrganization>
        }
      ></FormDialog>

      <Grid item xs={3} md={3}>
        <Typography
          fontSize="24px"
          fontFamily="Inter"
          fontWeight={700}
          data-testid="label_Organizations"
        >
          Organizations
        </Typography>
      </Grid>

      <Grid item xs={4} md={4} sx={{ textAlign: "center", mx: "auto" }}>
        <Search
          label="Search By Organization Name"
          searchFunction={organizationsFilter}
        />
      </Grid>

      <Grid item xs={3} md={3} textAlign="right">
        {(userRole === UserRoles.SuperAdmin ||
          userRole === UserRoles.Admin) && (
          <Button
            variant="contained"
            data-testid="portfolioViewBtn"
            onClick={() => {
              localStorage.setItem("HighlightsMenu", "Portfolio view");
              navigate("/Organization/Portfolioview/baselineEmissions");
            }}
            style={{ marginRight: "10px" }}
          >
            Portfolio View
          </Button>
        )}

        {userRole === UserRoles.SuperAdmin && (
          <Button
            variant="contained"
            data-testid="createnewbtn"
            onClick={() => {
              setDialogOpen(true);
              setDialogTitle("Create New Organization");
            }}
          >
            Create New
          </Button>
        )}
      </Grid>

      <Grid item xs={12} md={12}>
        <br />
        {!loading ? (
          renderOrganizations()
        ) : (
          <Spinner size={80} data-testid="spinner" />
        )}
      </Grid>
      <SnackBar
        show={success.show}
        message={success.message}
        severity={success.severity}
        onclose={() =>
          setSuccess({ show: false, severity: "info", message: "" })
        }
      />
    </>
  );
};

export default DisplayOrganizations;
