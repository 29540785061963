// Importing necessary modules and components from Material-UI
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

// Importing chart components and data
import { DataTable } from "components/DataTable";

// Importing utility functions and models
import numericValidator from "services/numericValidator";
import { OrganizationModel } from "object-models/organization-model";
import { _updateOrganization } from "middlewares/OrganizationApi/organization";

// Importing loading spinner component
import Spinner from "components/Spinner";

// Importing static data for base and target years
import { baseYears, targetYears } from "pages/TargetForm/data/TargetFormData";
import { BAUGHGEmissions } from "./data/BAUGHGEmissions";
import { AddGrowthRateProps } from "./data/AddGrowthRateProps";
import { GHGEmissionColumns } from "./data/GHGEmissionColumns";
import { LineChart } from "./LineChart";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import SnackBar from "components/SnackBar";
import { Constants } from "constants/constant";

/**
 * AddGrowthRate Component
 * @param {AddGrowthRateProps} props - Props for the AddGrowthRate component
 * @returns {JSX.Element} - The rendered component
 */
const AddGrowthRate = (props: AddGrowthRateProps) => {
  // Constants and initializations
  const headerLabels = ["Item", "Value", "Units"];
  const initialOrgDetails: OrganizationModel = {
    ...props.orgDetails,
    orgId: props.orgDetails?.orgId ?? 0,
    baseYear: props.orgDetails?.baseYear ?? Constants.BaseYear,
    targetYear: props.orgDetails?.targetYear ?? Constants.TargetYear,
    averageAnnualGrowthRate: props.orgDetails?.averageAnnualGrowthRate ?? 0,
  };

  // State variables
  const [loading, setLoading] = useState(false);
  const [orgDetails, setOrgDetails] =
    useState<OrganizationModel>(initialOrgDetails);
  const [growthOverTargetPeriod, setGrowthOverTargetPeriod] = useState<
    number | undefined
  >();
  const [calculatedAvgAnnualGrowthRate, setCalculatedAvgAnnualGrowthRate] =
    useState<number | undefined>();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [success, setSuccess] = useState<AlertsProps>({
    show: false,
    severity: "info",
    message: "",
  });

  const ghgEmissionsData: BAUGHGEmissions[] = useMemo(() => {
    const data = [];
    const baseYear = Number(orgDetails.baseYear);
    const targetYear = Number(orgDetails.targetYear);

    for (let i = 0; i <= targetYear - baseYear; i++) {
      const year = baseYear + i;
      const baselineTotalEmissions = Number(props.baselineTotalEmissions);

      const GHGEmissions =
        i === 0
          ? baselineTotalEmissions
          : (
              baselineTotalEmissions *
              (1 +
                ((orgDetails?.averageAnnualGrowthRate ?? 0) / 100) *
                  (year - baseYear))
            ).toFixed(2);

      const ghgEmission: BAUGHGEmissions = {
        nzdmId: i + 1,
        year: year,
        GHGEmissions: Number(GHGEmissions),
        units: "metric tons CO₂e",
      };

      data.push(ghgEmission);
    }

    return data;
  }, [
    orgDetails.baseYear,
    orgDetails.targetYear,
    props.baselineTotalEmissions,
    orgDetails.averageAnnualGrowthRate,
  ]);

  // Transform data for LineChart component
  const transformedData = ghgEmissionsData.map((item) => ({
    year: item.year,
    GHGEmissions: item.GHGEmissions,
  }));

  const labels = transformedData.map((item) => item.year.toString());
  const data = transformedData.map((item) => item.GHGEmissions);

  // Trigger growth calculation when emissions data changes

  useEffect(() => {
    // Calculate growth over the target period
    const calculateGrowthOverTargetPeriod = () => {
      const lastYearEmissions =
        ghgEmissionsData[ghgEmissionsData.length - 1].GHGEmissions;
      const initialYearEmissions = ghgEmissionsData[0].GHGEmissions;

      const growthOverPeriod = (
        (lastYearEmissions / initialYearEmissions - 1) *
        100
      ).toFixed(2);
      setGrowthOverTargetPeriod(Number(growthOverPeriod));
    };
    calculateGrowthOverTargetPeriod();
  }, [ghgEmissionsData]);

  // Trigger growth rate calculation when relevant data changes
  useEffect(() => {
    // Calculate average annual growth rate
    const calculateAvgAnnualGrowthRate = () => {
      const growthOverTargetPeriodPercentage =
        growthOverTargetPeriod ?? 0 / 100;
      const targetYearInt = Number(orgDetails.targetYear);
      const baseYearInt = Number(orgDetails.baseYear);

      if (
        !isNaN(growthOverTargetPeriodPercentage) &&
        targetYearInt !== baseYearInt
      ) {
        const avgAnnualGrowthRate = (
          growthOverTargetPeriodPercentage /
          (targetYearInt - baseYearInt)
        ).toFixed(2);
        setCalculatedAvgAnnualGrowthRate(Number(avgAnnualGrowthRate));
      }
    };

    calculateAvgAnnualGrowthRate();

    //button enable and disable logic
    const isNumeric =
      !isNaN(orgDetails.averageAnnualGrowthRate as number) &&
      orgDetails.averageAnnualGrowthRate?.toString() !== "" &&
      Number(orgDetails.averageAnnualGrowthRate) <= 100;
    setSaveButtonDisabled(!isNumeric);
  }, [
    growthOverTargetPeriod,
    orgDetails.targetYear,
    orgDetails.baseYear,
    orgDetails.averageAnnualGrowthRate,
  ]);

  /**
   * Handle text input changes
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent | SelectChangeEvent<number>} event - The change event
   */
  const handleTextChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
      | SelectChangeEvent<number>
  ) => {
    const newValue = numericValidator.allowNumericValue(
      event.target.value.toString()
    );
    setOrgDetails({
      ...orgDetails,
      [event.target.name]: newValue,
    });
    const isNumeric =
      !isNaN(newValue as number) && newValue !== "" && Number(newValue) <= 100;
    setSaveButtonDisabled(!isNumeric);
  };

  /**
   * Handle select input changes
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent | SelectChangeEvent<number>} event - The change event
   */
  const handleSelectChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
      | SelectChangeEvent<number>
  ) => {
    setOrgDetails({
      ...orgDetails,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * Save button click handler
   */
  const onSave = async () => {
    try {
      setLoading(true);
      const res = await _updateOrganization(orgDetails);
      if (res) {
        setLoading(false);
          props.fetchOrganizationDetails();
          props.handleCloseSnackBar({
            show: true,
            severity: "success",
            message: "Data Saved Successfully !",
          });
          props.closeDialog();
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle SnackBar close
   */
  const handleCloseSnackBar = () => {
    setSuccess({ show: false, severity: "info", message: "" });
  };

  // JSX for the component
  return (
    <>
      <Grid container spacing={2}>
        {/* left side design */}
        <Grid item xs={6}>
          <Grid container spacing={2} sx={{ mb: 1.5 }}>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 18,
                  fontWeight: "fontWeightBold",
                  color: "#718096",
                }}
                data-testid="growthRateCalculations"
              >
                Growth Rate Calculations
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                type="submit"
                variant="contained"
                data-testid="btnSave"
                disabled={saveButtonDisabled}
                onClick={() => onSave()}
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  padding: "16px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    backgroundColor: "#E9EFF5",
                    mb: 3,
                    height: "60px",
                    width: "730px",
                  }}
                >
                  {headerLabels.map((label, index) => (
                    <Grid
                      key={label}
                      item
                      xs={4}
                      sx={{
                        fontWeight: "fontWeightBold",
                      }}
                    >
                      <Typography variant="body2">{label}</Typography>
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="averageAnnualGrowthRateLabel"
                    >
                      Average Annual Growth Rate
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      name="averageAnnualGrowthRate"
                      onChange={(e: any) => {
                        handleTextChange(e);
                      }}
                      value={orgDetails.averageAnnualGrowthRate}
                      size="small"
                      fullWidth
                      inputProps={{
                        "data-testid": "averageAnnualGrowthRateValue",
                        inputMode: "numeric",
                        maxLength: 3,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="averageAnnualGrowthRateUnits"
                    >
                      %
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2.5 }} />
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="baselineTotalEmissionsLabel"
                    >
                      Baseline Total Emissions
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="baselineTotalEmissions"
                    >
                      {Number(props.baselineTotalEmissions).toLocaleString(
                        "en-US"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="baselineTotalEmissionsUnits"
                    >
                      metric tons CO₂e
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2.5 }} />
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="baselineYearLabel"
                    >
                      Baseline Year
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Select
                        data-testid="baseYearValue"
                        name="baseYear"
                        required
                        value={orgDetails.baseYear}
                        onChange={(e) => {
                          handleSelectChange(e);
                        }}
                        displayEmpty
                        size="small"
                        fullWidth
                      >
                        <MenuItem value="">Select Base Year</MenuItem>
                        {baseYears.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="baselineYearUnits"
                    >
                      Year
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2.5 }} />
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="targetYearLabel"
                    >
                      Target Year
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Select
                        value={orgDetails.targetYear}
                        required
                        data-testid="targetYear"
                        name="targetYear"
                        onChange={(e) => {
                          handleSelectChange(e);
                        }}
                        displayEmpty
                        size="small"
                        fullWidth
                      >
                        <MenuItem value="">Select Target Year</MenuItem>
                        {targetYears.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14 }}
                      data-testid="targetYearUnits"
                    >
                      Year
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 1.5 }}>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 18,
                  fontWeight: "fontWeightBold",
                  color: "#718096",
                }}
                data-testid="internalValidation"
              >
                Internal Validation
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  padding: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="growthOverTargetPeriodLabel"
                    >
                      Growth Over Target Period
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="growthOverTargetPeriod"
                    >
                      {growthOverTargetPeriod}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="growthOverTargetPeriodUnits"
                    >
                      %
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2.5 }} />
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="averageAnnualGrowthLabel"
                    >
                      Average Annual Growth Rate
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="averageAnnualGrowthRate"
                    >
                      {calculatedAvgAnnualGrowthRate}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: "light" }}
                      data-testid="baselineTotalEmissionsUnits"
                    >
                      metric tons CO₂e
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 18,
                  fontWeight: "fontWeightBold",
                  color: "#718096",
                  mb: 2,
                }}
                data-testid="businessAsUsualEmissionsLabel"
              >
                Business As Usual Emissions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  padding: "16px",
                }}
              >
                <LineChart labels={labels} data={data} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* right side */}
        <Grid item xs={6}>
          <Typography
            variant="body2"
            sx={{
              fontSize: 18,
              fontWeight: "fontWeightBold",
              color: "#718096",
              mb: 3.5,
            }}
            data-testid="BAUGHGEmissions"
          >
            BAU GHG Emissions
          </Typography>
          <Grid container>
            <Grid item xs={11} md={11} lg={11}>
              <DataTable
                columns={GHGEmissionColumns}
                rows={ghgEmissionsData}
                hidePagination={false}
                disableCheckBox={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loading && <Spinner size={80} data-testid="spinner" />}
      <SnackBar
        show={success.show}
        message={success.message}
        severity={success.severity}
        onclose={handleCloseSnackBar}
      />
    </>
  );
};

export default AddGrowthRate;
