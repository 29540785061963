// Main Icons
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import GamesOutlinedIcon from "@mui/icons-material/GamesOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import GroupIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PieChartIcon from "@mui/icons-material/PieChart";
import SettingsIcon from "@mui/icons-material/Settings";


const SimpleMenu = () => {
  const highlightMenu = localStorage.getItem("HighlightsMenu");
  const allMenus = [
    {
      id: 0,
      icon: (
        <AddBoxOutlinedIcon
          color={`${
            "Organization Details" === localStorage.getItem("HighlightsMenu")
              ? "primary"
              : "secondary"
          }`}
        />
      ),
      label: "Organization Details",
      link: "Organization-Details",
      allowedRoles: ["Super Admin", "Admin", "Standard User"],
      children: undefined,
    },
    {
      id: 1,
      icon: (
        <GamesOutlinedIcon
          color={`${
            "GHG Targets" === localStorage.getItem("HighlightsMenu")
              ? "primary"
              : "secondary"
          }`}
        />
      ),
      label: "GHG Targets",
      link: "Target-Form",
      allowedRoles: ["Super Admin", "Admin", "Standard User"],
      children: undefined,
    },
    {
      id: 2,
      icon: (
        <ViewInArOutlinedIcon
          color={`${
            "Reduction Measures" === localStorage.getItem("HighlightsMenu")
              ? "primary"
              : "secondary"
          }`}
        />
      ),
      label: "Reduction Measures",
      link: "Reduction-Measures",
      allowedRoles: ["Super Admin", "Admin", "Standard User"],
      children: undefined,
    },
    {
      id: 3,
      icon: (
        <GroupIcon
          color={`${
            "User Management" === localStorage.getItem("HighlightsMenu")
              ? "primary"
              : "secondary"
          }`}
        />
      ),
      label: "User Management",
      link: "user-management",
      allowedRoles: ["Super Admin", "Admin"],
      children: undefined,
    },
    {
      id: 4,
      icon: (
        <DashboardIcon
          color={`${
            "Dashboards" === localStorage.getItem("HighlightsMenu")
              ? "primary"
              : "secondary"
          }`}
        />
      ),
      label: "Dashboards",
      link: "baselinetargetemissions",
      allowedRoles: ["Super Admin", "Admin", "Standard User"],
      children: [
        {
          id: 40,
          icon: (
            <PieChartIcon
              color={`${
                "Dashboards" === localStorage.getItem("HighlightsMenu")
                  ? "primary"
                  : "secondary"
              }`}
            />
          ),
          label: "Baseline Emissions",
          link: "baselinetargetemissions",
          allowedRoles: ["Super Admin", "Admin", "Standard User"],
        },
        {
          id: 41,
          icon: (
            <PieChartIcon
              color={`${
                "Dashboards" === localStorage.getItem("HighlightsMenu")
                  ? "primary"
                  : "secondary"
              }`}
            />
          ),
          label: "Project Register",
          link: "projectregister",
          allowedRoles: ["Super Admin", "Admin", "Standard User"],
        },
        {
          id: 42,
          icon: (
            <PieChartIcon
              color={`${
                "Dashboards" === localStorage.getItem("HighlightsMenu")
                  ? "primary"
                  : "secondary"
              }`}
            />
          ),
          label: "Scenario View",
          link: "scenarioView",
          allowedRoles: ["Super Admin", "Admin", "Standard User"],
        },
      ],
    },
    {
      id: 5,
      icon: (
        <SettingsIcon
          color={`${
            "Settings" === localStorage.getItem("HighlightsMenu")
              ? "primary"
              : "secondary"
          }`}
        />
      ),
      label: "Settings",
      link: "prices",
      allowedRoles: ["Super Admin", "Admin"],
      children: [
        {
          id: 43,
          icon: (
            <PieChartIcon
              color={`${
                "Settings" === localStorage.getItem("HighlightsMenu")
                  ? "primary"
                  : "secondary"
              }`}
            />
          ),
          label: "Prices",
          link: "prices",
          allowedRoles: ["Super Admin", "Admin"],
        },
      ],
    },
    {
      id: 6,
      icon: (
        <SettingsIcon
          color={`${
            "Portfolio view" === localStorage.getItem("HighlightsMenu")
              ? "primary"
              : "secondary"
          }`}
        />
      ),
      label: "Portfolio view",
      link: "baselineEmissions",
      allowedRoles: ["Super Admin", "Admin"],
      children: [
        {
          id: 44,
          icon: (
            <PieChartIcon
              color={`${
                "Portfolio view " === localStorage.getItem("HighlightsMenu")
                  ? "primary"
                  : "secondary"
              }`}
            />
          ),
          label: "Baseline Emissions",
          link: "baselineEmissions",
          allowedRoles: ["Super Admin", "Admin"],
        },
      ],
    },
  ];

  // If "Portfolio view" was clicked, show only that menu item
  if (highlightMenu === "Portfolio view") {
    return allMenus.filter((menu) => menu.label === "Portfolio view");
  }
  // Otherwise, return all menu items
  return allMenus;
};

export default SimpleMenu;
